import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import BreadCrumbs from '../components/common/breadcrumbs'
import ReactPaginate from 'react-paginate';

const PortfolioPage = ({ data, pageContext }) => {

  const breadcrumbs = {
    'Home': '/',
    'Drupal Portfolio': null,
  }

  const pageCount = data.allNodePortfolio.pageInfo.pageCount;

  const handlePageClick = data => {
    const selected = data.selected + 1;
    if (selected === 1) {
      window.location.href = '/portfolio';
      return;
    }
    window.location.href = `/portfolio/page/${selected}`;
  };

  const page = +pageContext.skip / 10;
  const forcePage = page;

  return (
    <Layout pageTitle="Drupal Portfolio">
      <SEO title="Drupal Portfolio" description="Drupal Developer Toronto - please review my Drupal Developer Drupal Portfolio page"/>
      <div className="page-portfolio">
        <div className="container is-desktop">
          <div className="page-breadcrumbs notification">
            <BreadCrumbs input={breadcrumbs} />
          </div>

          <div className="portfolio-header">
            <div className="portfolio-content" dangerouslySetInnerHTML={{ __html: data.nodeConfigPage.body.processed }}>
            </div>
          </div>

          <hr style={{ margin: `50px 0` }} />

          <div className="teaser-content columns is-multiline is-vcentered">
            {
              data.allNodePortfolio.edges.map(node => {
                return (
                  <div className="column is-half" key={node.node.id}>
                    <Link to={node.node.path.alias} style={{ fontSize: `32px`, fontWeight: `900` }}>
                      <div className="card portfolio-card" style={{ height: `100%` }}>
                        <div className="card-image">
                          <figure className="image">
                            <Img style={{ width: `100%` }} fixed={node.node.relationships.field_image.localFile.childImageSharp.fixed} />
                          </figure>
                        </div>
                        <div className="card-content">
                          <div className="media">
                            <div className="media-left">
                            </div>
                            <div className="media-content">
                              <p className="title is-4" style={{ fontSize: `32px`, fontWeight: `900` }}>
                                {node.node.title}
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                )
              })
            }
          </div>

          <div className="columns is-mobile">
            <div className="column">
              <ReactPaginate
                previousLabel={'previous'}
                nextLabel={'next'}
                breakLabel={'...'}
                breakClassName={'break-me'}
                pageCount={pageCount}
                marginPagesDisplayed={2}
                pageRangeDisplayed={5}
                containerClassName={'pagination'}
                subContainerClassName={'pages pagination'}
                activeClassName={'active'}
                onPageChange={handlePageClick}
                forcePage={forcePage}
              />
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default PortfolioPage


export const query = graphql`
query($skip: Int!) {
  allNodePortfolio(
    filter: { status: { eq: true } }
    sort: { fields: created, order: DESC }
    limit: 10
    skip: $skip
  ) {
    edges {
      node {
        id
        title
        status
        body {
          value
          format
          processed
          summary
        }
        relationships {
          field_image {
            localFile {
              childImageSharp {
                fixed(width: 672) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
        path {
          alias
        }
        body {
          value
          format
          processed
          summary
        }
      }
    }
    pageInfo {
      currentPage
      hasNextPage
      hasPreviousPage
      itemCount
      pageCount
      perPage
    }
    totalCount
  }
  nodeConfigPage {
    body {
      value
      format
      processed
      summary
    }
  }
}
`


